<template>
  <div>
    <b-card-text>
      {{$t('Constructor.PropWidgets.ValidateMsgWidget.Info')}}
    </b-card-text>

    <b-form-group
        inline
        :label-cols-sm="4"
        :label-cols-lg="4"
        :label="$t('Constructor.PropWidgets.ValidateMsgWidget.ElementForError')"
        :title="$t('General.Base.Field')"
        v-b-tooltip.hover
    >
      <b-input-group>
        <b-form-select
            :title = "$t('Constructor.PropWidgets.ValidateMsgWidget.ElemForText')"
            class="col-12"
            :options="getAllElementsCurrentWinToOptions(true, {tag: ['div', 'span', 'p']})"
            v-model="dataObj.data.elem"
            v-b-tooltip.hover
        ></b-form-select>
        <b-form-text>
          {{$t('Constructor.PropWidgets.ValidateMsgWidget.ElemForTextDesc')}}
        </b-form-text>
      </b-input-group>
    </b-form-group>

    <h6>{{$t('Constructor.PropWidgets.ValidateMsgWidget.ErrorText')}}</h6>
    <b-form-group
        v-for="(elem, type) in dataObj.data.text"
        :key = type
        :label="nameFields[type]"
    >
      <b-input-group>
        <b-form-input
            class="col-12"
            :placeholder = "$t('General.Form.NoSet')"
            v-model="dataObj.data.text[type]"
        ></b-form-input>
      </b-input-group>
    </b-form-group>



  </div>
</template>

<script>
import TagDataEditService from "@/helpers/constructor/TagDataEditService";
import TagDataGetElemsHelper from "@/helpers/constructor/TagDataGetElemsHelper";
import HistoryHelper from '@/helpers/constructor/HistoryHelper'

export default {
  name: "ValidateMsgWidget",
  props: ["tagData", "settingConstructor", "basePropElems", "dataObj", "elem"],
  data: function() {
    return {
      nameFields: {
        type: this.$t('Constructor.PropWidgets.ValidateMsgWidget.NameFields.Type'),
        maxlength: this.$t('Constructor.PropWidgets.ValidateMsgWidget.NameFields.Maxlength'),
        required: this.$t('Constructor.PropWidgets.ValidateMsgWidget.NameFields.Required')
      },
      dataPattern: {
        elem: null,
        text: {
          // textRequired: "Поле обязательно для заполнения",
          // textMaxlength: "Превышено масимально допустимое кол-во символов ",
          // textNoValid: "Заполненные данные не верны"
        }
      }
    }
  },
  created() {
    if (!this.dataObj.data) {
      this.dataObj.data = this.dataPattern
    }

  },
  mounted() {
    this.getTextFields()
  },
  methods: {
    ...TagDataEditService,
    ...TagDataGetElemsHelper,
    ...HistoryHelper,
    getTextFields() {
      if (this.elem.main.attr_type === 'email') {
        this.dataObj.data.text.type = this.$t('Constructor.PropWidgets.ValidateMsgWidget.TextError.Type')
      }
      if (this.elem.main.attr_maxlength) {
        this.dataObj.data.text.maxlength = this.$t('Constructor.PropWidgets.ValidateMsgWidget.TextError.Maxlength') + ' ' + this.elem.main.attr_maxlength.val
      }
      if (this.elem.main.attr_required) {
        this.dataObj.data.text.required = this.$t('Constructor.PropWidgets.ValidateMsgWidget.TextError.Required')
      }
    }
  },
}
</script>
